<template>
	<div>
		<el-card body-style="{ padding: '10px 20px' }">
			<div class="flexRow titleCard">
				<img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/associateShare-img2.png" class="img" alt="">
				<div style="margin-left:5px">
					<div class="titFont">社群专享活动</div>
					<div class="secFont">基于企业微信客户群开展，进入活动指定企业微信客户群聊，客户可享受群内专属福利价优惠。
						<el-link href="https://jusnn6k8al.feishu.cn/docx/doxcnjPUgQqNSSHX6g2HcM7OIkf" target="_blank" :underline="false" type="primary">
							查看功能说明
						</el-link>
					</div>
				</div>
			</div>
		</el-card>
		<div class="association" style="margin-top:10px" v-loading='loading'>

			<div class="filter-container">
				<div class="filter-item">
					<label class="label" style="display: inline-block;width:75px;text-align: right;">关键字: </label>
					<el-input v-model="searchKey" placeholder="活动名称" style="width: 250px;margin-right: 10px;" clearable></el-input>
				</div>

				<div class="filter-item">
					<label class="label">活动状态: </label>
					<el-select v-model="searchState" style="width: 150px;margin-right: 10px;" clearable>
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="item in searchStateList" :key="item.id" :label="item.value" :value="item.id">
						</el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<el-button type="primary" @click="goodsFilter">查询</el-button>
					<el-button type="primary" style="margin-left: 15px;" @click="getNewGift({},2)">添加</el-button>
				</div>
			</div>

			<!-- 表格 -->
			<div class="table-container" style="margin-top:20px">
				<el-table :data="GiftData" style="width: 100%;" v-loading="loading" ref="multipleTable" :row-key="rowKeys">
					<el-table-column label="活动名称" min-width="250">
						<template slot-scope="scope">
							<div class="ellipsis-two">{{scope.row.ActivityName}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="AddTime" label="活动时间" min-width="200">
						<template slot-scope="scope">
							<div>{{scope.row.StartTime}}至</div>
							<div>{{scope.row.EndTime}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="StateValue" label="状态">
						<template slot-scope="scope">
							<div>{{scope.row.ActivityStateValue}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="StateValue" label="支付订单">
						<template slot-scope="scope">
							<el-button type="text" v-if="scope.row.PayOrderCount"
							 @click="goOrderpage(scope.row)">{{scope.row.PayOrderCount}}</el-button>
							<div v-else>{{scope.row.PayOrderCount}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="PayOrderMemberCount" label="支付人数"></el-table-column>
					<el-table-column prop="PayOrderMoney" label="支付金额(元)"></el-table-column>
					<el-table-column prop="AddTime" label="创建时间" width="160"></el-table-column>
					<el-table-column label="操作" width="180">
						<template slot-scope="scope">
							<div class="flexRow">
								<button-permissions :datas="'editAssociteShare'" style="margin-right:15px" v-if="scope.row.ActivityStateValue=='未开始' || scope.row.ActivityStateValue=='进行中'">
									<el-button type="text" @click="getNewGift(scope.row,1)">编辑</el-button>
								</button-permissions>
								<el-button type="text" style="margin-right:15px" @click="getNewGift(scope.row)" v-if="scope.row.ActivityStateValue=='已结束'">查看</el-button>
								
								<!-- v-if="scope.row.ActivityStateValue=='已结束'" -->
								<buttonPermissions :datas="'finishAssociteShare'" style="margin-right:15px" v-if="scope.row.ActivityStateValue=='未开始' || scope.row.ActivityStateValue=='进行中'">
									<el-button type="text" style="color:#409EFF;" @click="stopFun(scope.row)">使终止</el-button>
								</buttonPermissions>
								<button-permissions :datas="'copyAssociteShare'" style="margin-right:15px">
									<el-button type="text" style="color:#409EFF;" @click="getNewGift(scope.row,2)">复制</el-button>
								</button-permissions>
								
								<button-permissions :datas="'deleteAssociteShare'" style="margin-right:15px" v-if="scope.row.ActivityStateValue=='已结束'">
									<el-button type="text" style="color:red;" @click="delectFun(scope.row)">删除</el-button>
								</button-permissions>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div style="text-align: right;">
					<el-pagination v-if="Total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					:total="Total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	import {
		activityCommunityGroupList,
		activityCommunityGroupDelete,
		activityCommunityGroupOpen
	} from '@/api/TurnTomySelf'
	export default {
		components: {
			buttonPermissions,
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				loading: false,
				searchKey: '',
				searchState: null,
				searchStateList: [{
						id: 0,
						value: '未开始'
					},
					{
						id: 1,
						value: '进行中'
					},
					{
						id: 2,
						value: '已结束'
					}
				],
				GiftData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 0,
			}
		},
		created() {
			this.getList();
		},
		methods: {
			async getList() {
				try {
					this.loading = true
					let data = {
						Keywords: this.searchKey,
						ActivityState: this.searchState,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await activityCommunityGroupList(data)
					this.Total = result.Result.Total;
					this.GiftData = result.Result.Results || [];

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			goodsFilter() {
				this.currentPage = 1
				this.getList();
			},
			goOrderpage(record) {
				this.$router.push({
					path: '/order/orderMange',
					query: {
						ActivityId: record.Id ? record.Id : 0,
						ActivityType: 10
					}
				});
			},
			// 查看活动
			lookGiftActive(item) {
				this.$router.push({
					path: '/market/association/lookassociate',
					query: {
						Id: item.Id ? item.Id : 0,
					}
				});

			},

			// 添加
			getNewGift(item, type) {
				// 添加/复制是2 , 编辑是1
				this.$router.push({
					path: '/market/association/EditassociateData',
					query: {
						Id: item.Id ? item.Id : 0,
						type: type,
						status: item.ActivityStateValue ? item.ActivityStateValue : ''
					}
				});

			},
			rowKeys(row) {
				return row.Id
			},
			// 删除
			delectFun(row) {
				let message = '是否确认删除本场活动？删除后不可恢复'
				this.$confirm(message, '', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					dangerouslyUseHTMLString: true
				}).then(async () => {
					let data = {
						Id: row.Id
					}
					let result = await activityCommunityGroupDelete(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '删除成功!'
						});
						let currentPageTotal = this.Total - (this.currentPage - 1) * this.pageSize
						if (currentPageTotal == 1 && this.currentPage != 1) {
							this.currentPage -= 1
						}
						this.getList();
					}

				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				}).finally(() => {
					// this.goodsFilter();
				})
			},
			// 使终止
			stopFun(row) {
				let message = '活动终止后，你将不可再对活动进行编辑，是否确认使终止？'
				this.$confirm(message, '', {
					confirmButtonText: '确认终止',
					cancelButtonText: '关闭',
					dangerouslyUseHTMLString: true
				}).then(async () => {
					let result = await activityCommunityGroupOpen({
						Id: row.Id
					})
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
					}

				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				}).finally(() => {
					this.goodsFilter();
				})
			},

			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},
		}
	}
</script>

<style lang="less">
	.ellipsis-two {
		overflow          : hidden;
		text-overflow     : ellipsis;
		display           : -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		word-break: break-all;
	}
	.flexRow{
		display: flex;
		align-items: center;
	}
	.titleCard{
		.img{
			width: 60px;
			height: 60px;
			border-radius: 3px;
		}
		.titFont{
			font-size: 16px;
			color: #303133;
			line-height: 1.5;
		}
		.secFont{
			font-size: 14px;
			color: #606266;
			line-height: 1.5;
			margin-top: 5px;
		}
	}
	.association {
		padding: 10px;
		background: #fff;

		.product-info>img {
			width: 64px;
			height: 64px;
		}

		.table-button {
			color: #409EFF;
			display: inline-block;
			margin-right: 15px;
			cursor: pointer;
		}

		.pointers {
			cursor: pointer;
		}

		.towLines {
			word-break: break-all;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.ellipsisStyle {
			text-overflow: -o-ellipsis-lastline;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}
</style>
